import { rgba } from "polished";
import styled from "styled-components";

export const Section = styled.footer`
  background: #000;
  .container {
    @media only screen and (max-width: 1366px) {
      max-width: 1170px;
    }
    @media only screen and (max-width: 1024px) {
      max-width: 960px;
    }
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding-top: 40px;

  }
  .footer-wrapper__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-top: 1px solid ${rgba("#000", 0.1)};
  padding-top: 91px;
  padding-bottom: 84px;
  gap: 50px;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 480px) {
    gap: 40px 0px;
    grid-template-columns: repeat(1, 50%);
  }

`;

export const AboutUs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media screen and (min-width: 768px) {
    width: 230px;
  }
  @media screen and (min-width: 1024px) {
    width: 210px;
    margin-right: 40px;
  }
  @media screen and (min-width: 1280px) {
    width: 290px;
    margin-right: 60px;
  }
  @media screen and (min-width: 1366px) {
    margin-right: 60px;
    width: 250px;
  }
  @media screen and (min-width: 1440px) {
    margin-right: 90px;
    width: 285px;
  }
  @media screen and (max-width: 480px) {
    grid-column: 1/3;
  }
  img {
    width: 188px;
    height: 238px;
    margin-bottom: 25px;
    @media only screen and (max-width: 480px) {
      width: 100px;
      height: 150px;
    }
  }
  p {
    color: #fff;
    font-family: Poppins;
    line-height: normal;
    font-size: 15px;
    letter-spacing: -0.03px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 0;
    @media screen and (max-width: 480px) {
      font-size: 10px !important;
    }
  }
`;

export const FooterWidget = styled.div`
  h4 {
    color: #fff;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.04px;
    margin: 0 0 25px;
    @media screen and (max-width: 768px) {
      margin-bottom: 15px;
    }
    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }

  &:last-child {
    margin-right: 0;
  }
  .widgetListItem {
    a {
      color: #fff;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      // &:hover {
      //   color: #02073e;
      // }
      @media screen and (max-width: 480px) {
        font-size: 10px !important;
      }
    }
  }
`;

export const ContactInfo = styled.div`
  grid-column: 6/4;
  @media screen and (max-width: 768px) {
    grid-column: 2/4;
  }
  @media screen and (max-width: 480px) {
    grid-column: 1/3;
  }
  h4 {
    color: #fff;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.04px;

  }
`;

export const InfoItem = styled.div`
  color: #fff;
  display: flex;
  align-items: flex-start;
  font-family: Inter, sans-serif;
  font-size: 15px;
  @media screen and (max-width: 480px) {
    font-size: 10px !important;
  }
  line-height: 28px;
  letter-spacing: -0.002em;
  &.icon-list {
    display: flex;
    align-items: center;
    gap: 17px;

    div {
      width: 32px;
      height: 32px;
      background: #fff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      
      i {
        color: #000;
      }
    }
  }
`;

export const PolicyInfo = styled.div`
  margin-bottom: 48px;
  display: flex;
  gap: 50px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
  a {
    color: #fff;

    /* Regular/13px */
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    @media screen and (max-width: 480px) {
      font-size: 10px !important;
    }
  }
`;
