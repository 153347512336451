import React from "react";
import { Icon } from "react-icons-kit";
import { facebook } from "react-icons-kit/icomoon/facebook";
import { twitter } from "react-icons-kit/icomoon/twitter";
import { instagram } from "react-icons-kit/icomoon/instagram";
import { youtube } from "react-icons-kit/icomoon/youtube";
import { useStaticQuery, graphql } from "gatsby";
import Container from "common/components/UI/Container";
import Heading from "common/components/Heading";
import Image from "common/components/Image";
import Text from "common/components/Text";
import Link from "common/components/Link";
import {
  Section,
  Grid,
  AboutUs,
  FooterWidget,
  ContactInfo,
  InfoItem,
  PolicyInfo,
  FooterWrapper,
} from "./footer.style";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      saasAppCreativeJson {
        footer {
          about {
            logo {
              publicURL
            }
            text
          }
          contactInfo {
            address
            email
            openingTime
            phone
            title
          }
          widgets {
            id
            list {
              id
              link
              title
            }
            title
          }
        }
      }
    }
  `);
  const footer = data.saasAppCreativeJson.footer;
  return (
    <Section>
      <Container width="1360px">
        <FooterWrapper>
          <AboutUs>
            <Image src={footer.about.logo.publicURL} alt="SaaS App Creative" />
            <Text content={footer.about.text} />
          </AboutUs>
          <div className="footer-wrapper__info">
            <Grid>
              {footer.widgets.map((item) => (
                <FooterWidget key={item.id}>
                  <h4>{item.title}</h4>
                  <ul>
                    {item.list.map((item) => (
                      <li className="widgetListItem" key={item.id}>
                        <Link href={item.link}>{item.title}</Link>
                      </li>
                    ))}
                  </ul>
                </FooterWidget>
              ))}
              <ContactInfo>
                <Heading as="h4" content={footer.contactInfo.title} />
                <InfoItem>
                  <Text content={footer.contactInfo.address} />
                </InfoItem>
                {/*<InfoItem>*/}
                {/*  <Icon icon={ic_phone} size={26} className="phone-icon" />*/}
                {/*  <div>*/}
                {/*    <Text*/}
                {/*      className="phone-number"*/}
                {/*      content={footer.contactInfo.phone}*/}
                {/*    />*/}
                {/*    <Text content={footer.contactInfo.openingTime} />*/}
                {/*  </div>*/}
                {/*</InfoItem>*/}
                <InfoItem className="icon-list">
                  <div>
                    <a href="#">
                      <Icon icon={facebook} />
                    </a>
                  </div>
                  <div>
                    <a href="#">
                      <Icon icon={twitter} />
                    </a>
                  </div>
                  <div>
                    <a href="#">
                      <Icon icon={instagram} />
                    </a>
                  </div>
                  <div>
                    <a href="#">
                      <Icon icon={youtube} />
                    </a>
                  </div>
                </InfoItem>
              </ContactInfo>
            </Grid>
            <PolicyInfo>
              <a href="#">© Copyright 2023, All Rights Reserved by ezom.app</a>
              <a href="#">Terms and Conditions</a>
              <a href="https://hello.ezom.app/privacy-policy">
                Privacy Statement
              </a>
            </PolicyInfo>
          </div>
        </FooterWrapper>
      </Container>
    </Section>
  );
};

export default Footer;
